<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <div class="col-md-12">
        <Card :title="'Detail Obat'">
          <template v-slot:body>
            <div class="row align-items-center">
              <!-- Profile Image -->
              <div class="col-md-4">
                <div class="d-flex justify-content-center align-items-center image-container">
                  <div style='height: 350px; width: 350px; position: relative'>
                    <img
                      class="image"
                      :src="data.photo"
                      @load="onImageLoad()"
                    >
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div
                class="col"
              >
                <table class="table mb-3">
                  <tr>
                    <td><strong>Kode Inventori</strong></td>
                    <td>{{ data.inventory_code }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nama</strong></td>
                    <td>{{ data.name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Kategori</strong></td>
                    <td>{{ data.inventory_category_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Satuan</strong></td>
                    <td>{{ data.uom_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Tipe</strong></td>
                    <td>{{ data.inventory_type_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Status</strong></td>
                    <td>{{ data.inventory_status_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Merek</strong></td>
                    <td>{{ data.brand }}</td>
                  </tr>
                  <tr>
                    <td><strong>Serial Number</strong></td>
                    <td>{{ data.serial_number }}</td>
                  </tr>
                  <tr>
                    <td><strong>Tanggal Beli</strong></td>
                    <td>{{ data.buy_date_display }}</td>
                  </tr>
                  <tr>
                    <td><strong>Tanggal Tercatat Inventaris</strong></td>
                    <td>{{ data.inventory_date_display }}</td>
                  </tr>
                  <tr>
                    <td><strong>Keterangan</strong></td>
                    <td>{{ data.description }}</td>
                  </tr>
                </table>
                <button
                  @click="$router.push({name: 'inventory-edit', params: {id: data.id}})"
                  class="btn mx-1 btn-success"
                  v-if="btn"
                ><i class="far fa-fw fa-edit"></i> Edit</button>
                <button
                @click="deleteData"
                  class="btn mx-1 btn-danger"
                  v-if="btn"
                ><i class="far fa-fw fa-trash-alt"></i> Hapus</button>
              </div>
            </div>
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  name: 'Detail',

  components: {
    Card
  },

  data() {
    return {
      imgLoaded: true,
      data: [],
      // user access
      btn: false,
    }
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    },

    async deleteData() {
      let result = await module.delete('inventories/' + this.$route.params.id)
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push('/inventory/list')
      }
    },

    async get() {
      this.data = await module.get('inventories/' + this.$route.params.id)
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('/inventory/list')
      }
    },

    setActiveButton(){
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for(a = 0; a < access_right.length; a++){
        if(access_right[a] == "1802"){
          this.btn = true
        }
      }
    },

    convertDimension(dimension){
      let response = dimension
      if(dimension.split('.')[1] == '00'){
        response = dimension.split('.')[0]
      }

      return response
    }

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inventori", route: "" },
      { title: "Daftar Inventori", route: "/inventory/list" },
      { title: "Detail" },
    ])

    this.get()
    this.setActiveButton()
  },

}
</script>

<style>
/* Dynamic Size Image */
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>